main {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}

.sidebar {
  width: 280px;
  height: 100vh;
}

.content {
  margin: 30px;
}
