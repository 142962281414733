.notice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: flex-start;
  align-items: center;
  width: 100%;
  text-align: center;
}

.notice .icon {
  margin-bottom: 15px;
}

.notice h3 {
  text-align: center;
}

.notice span {
  width: 50%;
}
